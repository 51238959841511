import React, { useRef, useEffect } from "react";
import Navbar from '../components/NavbarLanding';
import HeroSection from '../components/HeroSectionLanding';
import StartSection from '../components/StartSectionLanding';
import HowCaddiWorksSection from '../components/HowCaddiWorksSectionLanding';
import UseCasesSection from '../components/UseCasesLanding';
import CaddiSecuritySection from '../components/CaddiSecuritySectionLanding';
import FooterSection from '../components/FooterLanding';

import './css/landingPage.css';
import './css/normalize.css';
import './css/webflow.css';
import './css/caddi-v1-2fa6e78753c11714081ab2fdc2abcb.webflow.css';

const LandingPage = () => {
    const sectionClientRef = useRef(null);

    useEffect(() => {
        const handleScrollToSection = (event) => {
            event.preventDefault();
            if (sectionClientRef.current) {
                sectionClientRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        };

        const learnMoreButton = document.getElementById('learnMoreButton');
        if (learnMoreButton) {
            learnMoreButton.addEventListener('click', handleScrollToSection);
        }

        return () => {
            if (learnMoreButton) {
                learnMoreButton.removeEventListener('click', handleScrollToSection);
            }
        };
    }, []);

    // TODO: FIX ANIMATION (it went broken when I set the css files to .landingPage so it would affect only this page)


    // To import scripts that give dinamic movement (not needed for now)
    // useEffect(() => {
    //     // Dynamically create script elements
    //     const script2 = document.createElement('script');
    //     script2.src = './webflow.js';
    //     script2.type = 'text/javascript';

    //     // Append script elements to the document's head
    //     document.head.appendChild(script2);

    //     // Cleanup function to remove the script elements when component unmounts
    //     return () => {
    //       document.head.removeChild(script2);
    //     };
    //   }, []); // Empty dependency array ensures the effect runs only once

    // // Expand burger menu on click (TODO: fix this because it doesn't work)
    // const expandMenu = () => {
    //     const menu = document.querySelector('.navbar4_menu-wrapper-inner');
    //     menu.classList.toggle('expand');
    // }

    return (
        <div className="page-wrapper">
            <Navbar />
            <main className="main-wrapper">
                <HeroSection />
                <StartSection />
                <UseCasesSection />
                <HowCaddiWorksSection />
                <CaddiSecuritySection />
                <FooterSection />
            </main>
        </div>
    );
};

export default LandingPage;