// Your React component where you want to use the HTML content
import React, { useState, useEffect } from "react";
// import mixpanel from "mixpanel-browser";
// import { Link, useNavigate } from "react-router-dom";
// import Navbar from "../components/Navbar";
// import { useAuth0 } from "@auth0/auth0-react";
// import LogoutButton from "../components/LogoutButton";
// import SignInButton from "../components/SignInButton";
// import useLocalStorage from "../hooks/useLocalStorage";
import axios from 'axios';

// import css
import './css/landingPage.css';
import './css/normalize.css';
import './css/webflow.css';
import './css/caddi-v1-2fa6e78753c11714081ab2fdc2abcb.webflow.css';
import './css/ScheduleDemo.css';

// import caddiLogo from './images/Caddi-Logo.svg';
import logoImage from './images/logo-without-text.svg';

const ScheduleDemo = () => {
  // const { isAuthenticated } = useAuth0();
  // const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [addedWaitlist, setAddedWaitlist] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [lawfirmName, setLawfirmName] = useState("");
  // const [isSignedIn, setIsSignedIn] = useLocalStorage("isSignedIn", false);
  // const [isLawyer, setIsLawyer] = useLocalStorage("isLawyer", false);
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  // let loginScreen = "/loginLawyer";
  // let logoutScreen = "/logout";

  // const logOutButtonStyles = {
  //   color: "white",
  //   backgroundColor: "black",
  // };

  // if (isLawyer) {
  //   logoutScreen = "/logoutLawyer";
  // }

  // const [isMenuExpanded, setIsMenuExpanded] = useState(false);
  // const navbarHeight = '120px';
  // const toggleMenu = () => {
  //   setIsMenuExpanded(current => !current);
  // };

  // useEffect(() => {
  //   const handleOutsideClick = (event) => {
  //     const navbar = document.querySelector('.navbar4_component');
  //     if (isMenuExpanded && navbar && !navbar.contains(event.target)) {
  //       setIsMenuExpanded(false);
  //     }
  //   };

  //   document.addEventListener('click', handleOutsideClick);
  //   return () => {
  //     document.removeEventListener('click', handleOutsideClick);
  //   };
  // }, [isMenuExpanded]);

  // useEffect(() => {
  //   const handleMenuAnimation = () => {
  //     const menuIcon = document.querySelector('.menu-icon4');
  //     const topLine = document.querySelector('.menu-icon4_line-top');
  //     const middleLine = document.querySelector('.menu-icon4_line-middle');
  //     const bottomLine = document.querySelector('.menu-icon4_line-bottom');

  //     if (isMenuExpanded) {
  //       menuIcon.classList.add('open');
  //       topLine.style.transform = 'rotate(45deg) translate(6px, 6px)';
  //       middleLine.style.opacity = '0';
  //       bottomLine.style.transform = 'rotate(-45deg) translate(6px, -6px)';
  //     } else {
  //       menuIcon.classList.remove('open');
  //       topLine.style.transform = 'rotate(0) translate(0, 0)';
  //       middleLine.style.opacity = '1';
  //       bottomLine.style.transform = 'rotate(0) translate(0, 0)';
  //     }
  //   };

  //   handleMenuAnimation();
  // }, [isMenuExpanded]);

  const validateForm = () => {
    const errors = {};
    if (!name.trim()) errors.name = "Name is required";
    if (!email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email is invalid";
    }
    if (!lawfirmName.trim()) errors.lawfirmName = "Firm Name is required";
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const submitDemoRequest = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return; // Stop submission if validation fails
    }

    if (isLoading) {
      return; // Prevent multiple submissions
    }

    setIsLoading(true);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_HOSTNAME}/schedule-demo`, {
        name: name,
        email: email,
        companyName: lawfirmName
      });

      if (response.status === 201) {
        setAddedWaitlist(true);
        setSubmitted(true);
      } else {
        console.error('Unexpected response:', response);
      }
    } catch (error) {
      console.error('Error submitting demo request:', error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <div className="page-wrapper">
        <div
          data-animation="default"
          className="navbar4_component w-nav"
          data-easing2="ease"
          fs-scrolldisable-element="smart-nav"
          data-easing="ease"
          data-collapse="all"
          data-w-id="e13ab862-2b2a-b56e-232c-9e2d2f0da996"
          role="banner"
          data-duration={400}
          data-doc-height={1}
        >
          <div className="navbar4_container">
            <a
              href="/"
              aria-current="page"
              className="navbar4_logo-link w-nav-brand w--current"
            >
              <img
                src={logoImage}
                loading="lazy"
                alt=""
                className="navbar4_logo"
              />
            </a>
            <div className="navbar4_wrapper">
            </div>
          </div>
        </div>
        {/* <div className="styles---glowing-button w-embed">

        </div> */}
        <div className="global-styles w-embed">

        </div>
        <main className="main-wrapper">
          <section className="section_hero">
            <div className="padding-global">
              <div className="container-large">
                <div className="hero_component">
                  <div className="hero_content">

                    <h1
                      data-w-id="301326fa-a9f6-d3f0-1b47-9b6b4c42ac69"
                      style={{
                        color: '#26443C',
                        fontSize: '3.5rem',
                        lineHeight: '1.2',
                        marginBottom: '0.5rem'
                      }}
                    >
                      Sign up for
                      <span className="text-color-primary"> demo</span>

                    </h1>
                    <p
                      data-w-id="6233d2b3-6ab8-4946-1015-4655c118456a"
                      style={{ opacity: 100 }}
                      className="hero_para"
                    >
                      Say goodbye to manually transferring data between tools or repeating the same tasks over and over. With Caddi, efficiency becomes effortless — all with the help of AI
                    </p>

                  </div>
                  <div
                    data-w-id="bd87e84f-d41e-8853-5454-50243a8d17eb"
                    style={{ opacity: 100 }}
                    className="hero_media-wrap"
                  >

                  {/* Form */}
                  <div className="form-container" style={{ textAlign: 'left', maxWidth: '600px', margin: '0 auto' }}>
                    <div className="form-description" style={{ color: '#FFFFFF', marginBottom: '20px' }}>
                    </div>
                    <p style={{ marginBottom: '5px' }}> Name </p>
                    <input
                      type="text"
                      onChange={(e) => setName(e.target.value)}
                      className={`input-field ${formErrors.name ? 'error' : ''}`}
                      style={{
                        width: '100%',
                        padding: '10px',
                        marginBottom: '15px',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        fontSize: '16px'
                      }}
                    />
                    {formErrors.name && <p className="error-message" style={{ color: 'red', marginTop: '-10px', marginBottom: '15px' }}>{formErrors.name}</p>}

                    <p style={{ marginBottom: '5px' }}> E-mail </p>
                    <input
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                      className={`input-field ${formErrors.email ? 'error' : ''}`}
                      style={{
                        width: '100%',
                        padding: '10px',
                        marginBottom: '15px',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        fontSize: '16px'
                      }}
                    />
                    {formErrors.email && <p className="error-message" style={{ color: 'red', marginTop: '-10px', marginBottom: '15px' }}>{formErrors.email}</p>}

                    <p style={{ marginBottom: '5px' }}> Company Name </p>
                    <input
                      type="text"
                      onChange={(e) => setLawfirmName(e.target.value)}
                      className={`input-field ${formErrors.lawfirmName ? 'error' : ''}`}
                      style={{
                        width: '100%',
                        padding: '10px',
                        marginBottom: '15px',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        fontSize: '16px'
                      }}
                    />
                    {formErrors.lawfirmName && <p className="error-message" style={{ color: 'red', marginTop: '-10px', marginBottom: '15px' }}>{formErrors.lawfirmName}</p>}

                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                      <div
                        data-w-id="a3f5c7ba-60a8-f0fe-c907-797341d9f454"
                        style={{ opacity: 100 }}
                        className="button-group hero_button-group centered-button"
                      >
                        <button
                          className="button w-button"
                          onClick={submitDemoRequest}
                          disabled={isLoading || submitted}
                        >
                          {isLoading ? 'Submitting...' : submitted ? 'Submitted' : 'Submit'}
                        </button>
                      </div>
                    </div>
                  </div>

                  </div>
                </div>
              </div>
            </div>
          </section>

        </main>
      </div>
    </>

  );
};

export default ScheduleDemo;